import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Quote } from '../../models/proto/pricing_model_pb';

export interface LastQuoteState {
    [symbol: string]: Quote;
}

export interface SystemState {
    lastQuoteList: LastQuoteState;
}

const initialState: SystemState = {
    lastQuoteList: {} as LastQuoteState,
}

export const systemSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        updateLastQuote: (state, action: PayloadAction<Quote>) => {
            const quote = action.payload as Quote;
            state.lastQuoteList = { ...state.lastQuoteList, [quote.getSymbol()]: quote as Quote}
        }
    }
})

export const {updateLastQuote} = systemSlice.actions;
export default systemSlice.reducer;