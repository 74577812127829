import { RpcService, WebsocketState } from "../models/constants";
import { GetLastQuoteRequest, SubscribeQuoteRequest, UnsubscribeQuoteRequest } from "../models/proto/pricing_service_pb";
import { Payload, RpcMessage } from "../models/proto/rpc_pb";
import { convertNumber, isNull } from ".";

const buildRpcMessage = () => {
    let rpcMessage: RpcMessage = new RpcMessage();
    rpcMessage.setVersion("1");
    rpcMessage.setId(new Date().getTime());
    return rpcMessage;
}

const sendRpcMessage = (ws: any, service: string, clazz: any, data: Uint8Array | string) => {
    try {
        let rpcMessage: RpcMessage = buildRpcMessage();
        rpcMessage.setService(service);
        rpcMessage.setPayloadClass(clazz);
        rpcMessage.setPayloadData(data);
        if (!isNull(ws) && ws.readyState === WebsocketState.OPEN)
            ws.send(rpcMessage.serializeBinary());
        else {
            setTimeout(() => {
                sendRpcMessage(ws, service, clazz, data)
            }, 1000);
        }
    } catch (e) { }
}


export const subcribeQuotes = (ws: any, symbols: string[]) => {
    let subQuoteRequest: SubscribeQuoteRequest = new SubscribeQuoteRequest();
    subQuoteRequest.setSymbolList(symbols)
    sendRpcMessage(ws, RpcService.PRICING, Payload.PRICING_SUBSCRIBE_QUOTE_REQ, subQuoteRequest.serializeBinary());
}

export const unsubcribeQuotes = (ws: any, symbols: string[]) => {
    let unsubQuoteRequest: UnsubscribeQuoteRequest = new UnsubscribeQuoteRequest();
    unsubQuoteRequest.setSymbolList(symbols);
    sendRpcMessage(ws, RpcService.PRICING, Payload.PRICING_UNSUBSCRIBE_QUOTE_REQ, unsubQuoteRequest.serializeBinary());
}

export const getLastQuotes = (ws: any, listSymbol: string[]) => {
    let lastQuotesRequest = new GetLastQuoteRequest();
    lastQuotesRequest.setSymbolList(listSymbol);
    console.log(lastQuotesRequest)
    sendRpcMessage(ws, RpcService.PRICING, Payload.PRICING_LAST_QUOTE_REQ, lastQuotesRequest.serializeBinary());
}