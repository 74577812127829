import { configureStore } from '@reduxjs/toolkit';
import quoteReducer from "./slice/quote.slice";
import systemReducer from "./slice/system.slice";

export const store = configureStore({
    reducer: {
        quote: quoteReducer,
        system: systemReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;