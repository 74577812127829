// source: system_model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.MT5WT.AccountBalance', null, global);
goog.exportSymbol('proto.MT5WT.AccountInfo', null, global);
goog.exportSymbol('proto.MT5WT.ApiData', null, global);
goog.exportSymbol('proto.MT5WT.Books', null, global);
goog.exportSymbol('proto.MT5WT.ExecutionNotiFlag', null, global);
goog.exportSymbol('proto.MT5WT.Group', null, global);
goog.exportSymbol('proto.MT5WT.IsAdmin', null, global);
goog.exportSymbol('proto.MT5WT.Language', null, global);
goog.exportSymbol('proto.MT5WT.LoginInfo', null, global);
goog.exportSymbol('proto.MT5WT.MarginMode', null, global);
goog.exportSymbol('proto.MT5WT.MsgCode', null, global);
goog.exportSymbol('proto.MT5WT.Noti', null, global);
goog.exportSymbol('proto.MT5WT.NotiType', null, global);
goog.exportSymbol('proto.MT5WT.PositionRisk', null, global);
goog.exportSymbol('proto.MT5WT.ShowSwap', null, global);
goog.exportSymbol('proto.MT5WT.UsersRights', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.LoginInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.LoginInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.LoginInfo.displayName = 'proto.MT5WT.LoginInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.AccountBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.AccountBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.AccountBalance.displayName = 'proto.MT5WT.AccountBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.AccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.AccountInfo.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.AccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.AccountInfo.displayName = 'proto.MT5WT.AccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.ApiData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.ApiData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.ApiData.displayName = 'proto.MT5WT.ApiData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.Noti = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.Noti, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.Noti.displayName = 'proto.MT5WT.Noti';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.PositionRisk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.PositionRisk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.PositionRisk.displayName = 'proto.MT5WT.PositionRisk';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.LoginInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.LoginInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.LoginInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.LoginInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    macAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    localIp: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.LoginInfo}
 */
proto.MT5WT.LoginInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.LoginInfo;
  return proto.MT5WT.LoginInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.LoginInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.LoginInfo}
 */
proto.MT5WT.LoginInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.LoginInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.LoginInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.LoginInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.LoginInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocalIp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.MT5WT.LoginInfo.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.LoginInfo} returns this
 */
proto.MT5WT.LoginInfo.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.MT5WT.LoginInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.LoginInfo} returns this
 */
proto.MT5WT.LoginInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mac_address = 3;
 * @return {string}
 */
proto.MT5WT.LoginInfo.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.LoginInfo} returns this
 */
proto.MT5WT.LoginInfo.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string local_ip = 4;
 * @return {string}
 */
proto.MT5WT.LoginInfo.prototype.getLocalIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.LoginInfo} returns this
 */
proto.MT5WT.LoginInfo.prototype.setLocalIp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.AccountBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.AccountBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.AccountBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.AccountBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    margin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marginLevel: jspb.Message.getFieldWithDefault(msg, 5, ""),
    marginFree: jspb.Message.getFieldWithDefault(msg, 6, ""),
    profit: jspb.Message.getFieldWithDefault(msg, 7, ""),
    credit: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.AccountBalance}
 */
proto.MT5WT.AccountBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.AccountBalance;
  return proto.MT5WT.AccountBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.AccountBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.AccountBalance}
 */
proto.MT5WT.AccountBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMargin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginLevel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginFree(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.AccountBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.AccountBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.AccountBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.AccountBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMargin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarginLevel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMarginFree();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProfit();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCredit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string balance = 2;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string equity = 3;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string margin = 4;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getMargin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setMargin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string margin_level = 5;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getMarginLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setMarginLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string margin_free = 6;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getMarginFree = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setMarginFree = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string profit = 7;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getProfit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setProfit = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string credit = 8;
 * @return {string}
 */
proto.MT5WT.AccountBalance.prototype.getCredit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountBalance} returns this
 */
proto.MT5WT.AccountBalance.prototype.setCredit = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.AccountInfo.repeatedFields_ = [42];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.AccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.AccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.AccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.AccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, 0),
    group: jspb.Message.getFieldWithDefault(msg, 2, ""),
    certSerialNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rights: jspb.Message.getFieldWithDefault(msg, 4, 0),
    registration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastAccess: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastPassChange: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastIp: jspb.Message.getFieldWithDefault(msg, 8, ""),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    account: jspb.Message.getFieldWithDefault(msg, 13, ""),
    country: jspb.Message.getFieldWithDefault(msg, 14, ""),
    language: jspb.Message.getFieldWithDefault(msg, 15, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    city: jspb.Message.getFieldWithDefault(msg, 17, ""),
    state: jspb.Message.getFieldWithDefault(msg, 18, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 19, ""),
    address: jspb.Message.getFieldWithDefault(msg, 20, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 21, ""),
    email: jspb.Message.getFieldWithDefault(msg, 22, ""),
    id: jspb.Message.getFieldWithDefault(msg, 23, ""),
    status: jspb.Message.getFieldWithDefault(msg, 24, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 25, ""),
    color: jspb.Message.getFieldWithDefault(msg, 26, ""),
    phonePassword: jspb.Message.getFieldWithDefault(msg, 27, ""),
    leverage: jspb.Message.getFieldWithDefault(msg, 28, ""),
    agent: jspb.Message.getFieldWithDefault(msg, 29, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 30, ""),
    credit: jspb.Message.getFieldWithDefault(msg, 31, ""),
    interestRate: jspb.Message.getFieldWithDefault(msg, 32, ""),
    commissionDaily: jspb.Message.getFieldWithDefault(msg, 33, ""),
    commissionMonthly: jspb.Message.getFieldWithDefault(msg, 34, ""),
    commissionAgentDaily: jspb.Message.getFieldWithDefault(msg, 35, ""),
    commissionAgentMonthly: jspb.Message.getFieldWithDefault(msg, 36, ""),
    balancePrevDay: jspb.Message.getFieldWithDefault(msg, 37, ""),
    balancePrevMonth: jspb.Message.getFieldWithDefault(msg, 38, ""),
    equityPrevDay: jspb.Message.getFieldWithDefault(msg, 39, ""),
    equityPrevMonth: jspb.Message.getFieldWithDefault(msg, 40, ""),
    tradeAccounts: jspb.Message.getFieldWithDefault(msg, 41, ""),
    apidataList: jspb.Message.toObjectList(msg.getApidataList(),
    proto.MT5WT.ApiData.toObject, includeInstance),
    mqid: jspb.Message.getFieldWithDefault(msg, 43, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 44, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.AccountInfo}
 */
proto.MT5WT.AccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.AccountInfo;
  return proto.MT5WT.AccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.AccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.AccountInfo}
 */
proto.MT5WT.AccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCertSerialNumber(value);
      break;
    case 4:
      var value = /** @type {!proto.MT5WT.UsersRights} */ (reader.readEnum());
      msg.setRights(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegistration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastAccess(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastPassChange(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastIp(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonePassword(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeverage(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgent(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredit(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestRate(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommissionDaily(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommissionMonthly(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommissionAgentDaily(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommissionAgentMonthly(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalancePrevDay(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalancePrevMonth(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquityPrevDay(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquityPrevMonth(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeAccounts(value);
      break;
    case 42:
      var value = new proto.MT5WT.ApiData;
      reader.readMessage(value,proto.MT5WT.ApiData.deserializeBinaryFromReader);
      msg.addApidata(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqid(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.AccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.AccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.AccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.AccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCertSerialNumber();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRights();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRegistration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastAccess();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLastPassChange();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLastIp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPhonePassword();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getLeverage();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getAgent();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCredit();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getInterestRate();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getCommissionDaily();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getCommissionMonthly();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getCommissionAgentDaily();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getCommissionAgentMonthly();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getBalancePrevDay();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getBalancePrevMonth();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getEquityPrevDay();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getEquityPrevMonth();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getTradeAccounts();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getApidataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      42,
      f,
      proto.MT5WT.ApiData.serializeBinaryToWriter
    );
  }
  f = message.getMqid();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
};


/**
 * optional int64 login = 1;
 * @return {number}
 */
proto.MT5WT.AccountInfo.prototype.getLogin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLogin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 cert_serial_number = 3;
 * @return {number}
 */
proto.MT5WT.AccountInfo.prototype.getCertSerialNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCertSerialNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional UsersRights rights = 4;
 * @return {!proto.MT5WT.UsersRights}
 */
proto.MT5WT.AccountInfo.prototype.getRights = function() {
  return /** @type {!proto.MT5WT.UsersRights} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.MT5WT.UsersRights} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setRights = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 registration = 5;
 * @return {number}
 */
proto.MT5WT.AccountInfo.prototype.getRegistration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setRegistration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_access = 6;
 * @return {number}
 */
proto.MT5WT.AccountInfo.prototype.getLastAccess = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLastAccess = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_pass_change = 7;
 * @return {number}
 */
proto.MT5WT.AccountInfo.prototype.getLastPassChange = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLastPassChange = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string last_ip = 8;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getLastIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLastIp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string first_name = 10;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string last_name = 11;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string middle_name = 12;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string account = 13;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string country = 14;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string language = 15;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string client_id = 16;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string city = 17;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string state = 18;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string zip_code = 19;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string address = 20;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string phone = 21;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string email = 22;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string id = 23;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string status = 24;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string comment = 25;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string color = 26;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string phone_password = 27;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getPhonePassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setPhonePassword = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string leverage = 28;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getLeverage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setLeverage = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string agent = 29;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string balance = 30;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string credit = 31;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCredit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCredit = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string interest_rate = 32;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getInterestRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setInterestRate = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string commission_daily = 33;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCommissionDaily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCommissionDaily = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string commission_monthly = 34;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCommissionMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCommissionMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string Commission_agent_daily = 35;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCommissionAgentDaily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCommissionAgentDaily = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string commission_agent_monthly = 36;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCommissionAgentMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCommissionAgentMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string balance_prev_day = 37;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getBalancePrevDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setBalancePrevDay = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string balance_prev_month = 38;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getBalancePrevMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setBalancePrevMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string equity_prev_day = 39;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getEquityPrevDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setEquityPrevDay = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string equity_prev_month = 40;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getEquityPrevMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setEquityPrevMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string trade_accounts = 41;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getTradeAccounts = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setTradeAccounts = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * repeated ApiData apidata = 42;
 * @return {!Array<!proto.MT5WT.ApiData>}
 */
proto.MT5WT.AccountInfo.prototype.getApidataList = function() {
  return /** @type{!Array<!proto.MT5WT.ApiData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MT5WT.ApiData, 42));
};


/**
 * @param {!Array<!proto.MT5WT.ApiData>} value
 * @return {!proto.MT5WT.AccountInfo} returns this
*/
proto.MT5WT.AccountInfo.prototype.setApidataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 42, value);
};


/**
 * @param {!proto.MT5WT.ApiData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.ApiData}
 */
proto.MT5WT.AccountInfo.prototype.addApidata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 42, opt_value, proto.MT5WT.ApiData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.clearApidataList = function() {
  return this.setApidataList([]);
};


/**
 * optional string mqid = 43;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getMqid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setMqid = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string currency = 44;
 * @return {string}
 */
proto.MT5WT.AccountInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.AccountInfo} returns this
 */
proto.MT5WT.AccountInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.ApiData.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.ApiData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.ApiData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.ApiData.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valueInt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    valueUint: jspb.Message.getFieldWithDefault(msg, 4, 0),
    valueDouble: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.ApiData}
 */
proto.MT5WT.ApiData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.ApiData;
  return proto.MT5WT.ApiData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.ApiData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.ApiData}
 */
proto.MT5WT.ApiData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValueInt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValueUint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueDouble(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.ApiData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.ApiData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.ApiData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.ApiData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getValueInt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getValueUint();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getValueDouble();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 app_id = 1;
 * @return {number}
 */
proto.MT5WT.ApiData.prototype.getAppId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.ApiData} returns this
 */
proto.MT5WT.ApiData.prototype.setAppId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.MT5WT.ApiData.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.ApiData} returns this
 */
proto.MT5WT.ApiData.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 value_int = 3;
 * @return {number}
 */
proto.MT5WT.ApiData.prototype.getValueInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.ApiData} returns this
 */
proto.MT5WT.ApiData.prototype.setValueInt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 value_uint = 4;
 * @return {number}
 */
proto.MT5WT.ApiData.prototype.getValueUint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.ApiData} returns this
 */
proto.MT5WT.ApiData.prototype.setValueUint = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string value_double = 5;
 * @return {string}
 */
proto.MT5WT.ApiData.prototype.getValueDouble = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ApiData} returns this
 */
proto.MT5WT.ApiData.prototype.setValueDouble = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.Noti.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.Noti.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.Noti} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Noti.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.Noti}
 */
proto.MT5WT.Noti.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.Noti;
  return proto.MT5WT.Noti.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.Noti} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.Noti}
 */
proto.MT5WT.Noti.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.MT5WT.NotiType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.Noti.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.Noti.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.Noti} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Noti.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional NotiType type = 1;
 * @return {!proto.MT5WT.NotiType}
 */
proto.MT5WT.Noti.prototype.getType = function() {
  return /** @type {!proto.MT5WT.NotiType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.MT5WT.NotiType} value
 * @return {!proto.MT5WT.Noti} returns this
 */
proto.MT5WT.Noti.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.MT5WT.Noti.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Noti} returns this
 */
proto.MT5WT.Noti.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.MT5WT.Noti.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Noti} returns this
 */
proto.MT5WT.Noti.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.PositionRisk.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.PositionRisk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.PositionRisk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.PositionRisk.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    positionBuy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    costRateBuy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    positionSell: jspb.Message.getFieldWithDefault(msg, 4, ""),
    costRateSell: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.PositionRisk}
 */
proto.MT5WT.PositionRisk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.PositionRisk;
  return proto.MT5WT.PositionRisk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.PositionRisk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.PositionRisk}
 */
proto.MT5WT.PositionRisk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPositionBuy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostRateBuy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionSell(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostRateSell(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.PositionRisk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.PositionRisk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.PositionRisk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.PositionRisk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPositionBuy();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCostRateBuy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPositionSell();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCostRateSell();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.MT5WT.PositionRisk.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.PositionRisk} returns this
 */
proto.MT5WT.PositionRisk.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 position_buy = 2;
 * @return {number}
 */
proto.MT5WT.PositionRisk.prototype.getPositionBuy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.PositionRisk} returns this
 */
proto.MT5WT.PositionRisk.prototype.setPositionBuy = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cost_rate_buy = 3;
 * @return {string}
 */
proto.MT5WT.PositionRisk.prototype.getCostRateBuy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.PositionRisk} returns this
 */
proto.MT5WT.PositionRisk.prototype.setCostRateBuy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string position_sell = 4;
 * @return {string}
 */
proto.MT5WT.PositionRisk.prototype.getPositionSell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.PositionRisk} returns this
 */
proto.MT5WT.PositionRisk.prototype.setPositionSell = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cost_rate_sell = 5;
 * @return {string}
 */
proto.MT5WT.PositionRisk.prototype.getCostRateSell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.PositionRisk} returns this
 */
proto.MT5WT.PositionRisk.prototype.setCostRateSell = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.MT5WT.MarginMode = {
  MARGIN_MODE_RETAIL: 0,
  MARGIN_MODE_EXCHANGE_DISCOUNT: 1,
  MARGIN_MODE_RETAIL_HEDGED: 2
};

/**
 * @enum {number}
 */
proto.MT5WT.UsersRights = {
  USER_RIGHT_NONE: 0,
  USER_RIGHT_ENABLED: 1,
  USER_RIGHT_TRADE_DISABLED: 2,
  USER_RIGHT_PASSWORD: 4,
  USER_RIGHT_INVESTOR: 8,
  USER_RIGHT_CONFIRMED: 16,
  USER_RIGHT_TRAILING: 32,
  USER_RIGHT_EXPERT: 64,
  USER_RIGHT_OBSOLETE: 128,
  USER_RIGHT_REPORTS: 256,
  USER_RIGHT_READONLY: 512,
  USER_RIGHT_RESET_PASS: 1024,
  USER_RIGHT_OTP_ENABLED: 2048,
  USER_RIGHT_SPONSORED_HOSTING: 8192,
  USER_RIGHT_API_ENABLED: 16384,
  USER_RIGHT_PUSH_NOTIFICATION: 32768
};

/**
 * @enum {number}
 */
proto.MT5WT.MsgCode = {
  MT_RET_OK: 0,
  MT_RET_OK_NONE: 1,
  MT_RET_ERROR: 2,
  MT_RET_ERR_PARAMS: 3,
  MT_RET_ERR_DATA: 4,
  MT_RET_ERR_DISK: 5,
  MT_RET_ERR_MEM: 6,
  MT_RET_ERR_NETWORK: 7,
  MT_RET_ERR_PERMISSIONS: 8,
  MT_RET_ERR_TIMEOUT: 9,
  MT_RET_ERR_CONNECTION: 10,
  MT_RET_ERR_NOSERVICE: 11,
  MT_RET_ERR_FREQUENT: 12,
  MT_RET_ERR_NOTFOUND: 13,
  MT_RET_ERR_PARTIAL: 14,
  MT_RET_ERR_SHUTDOWN: 15,
  MT_RET_ERR_CANCEL: 16,
  MT_RET_ERR_DUPLICATE: 17,
  MT_RET_BAD_ACCOUNT_INFO: 65,
  MT_RET_AUTH_CLIENT_INVALID: 1000,
  MT_RET_AUTH_ACCOUNT_INVALID: 1001,
  MT_RET_AUTH_ACCOUNT_DISABLED: 1002,
  MT_RET_AUTH_ADVANCED: 1003,
  MT_RET_AUTH_CERTIFICATE: 1004,
  MT_RET_AUTH_CERTIFICATE_BAD: 1005,
  MT_RET_AUTH_NOTCONFIRMED: 1006,
  MT_RET_AUTH_SERVER_INTERNAL: 1007,
  MT_RET_AUTH_SERVER_BAD: 1008,
  MT_RET_AUTH_UPDATE_ONLY: 1009,
  MT_RET_AUTH_CLIENT_OLD: 1010,
  MT_RET_AUTH_MANAGER_NOCONFIG: 1011,
  MT_RET_AUTH_MANAGER_IPBLOCK: 1012,
  MT_RET_AUTH_GROUP_INVALID: 1013,
  MT_RET_AUTH_CA_DISABLED: 1014,
  MT_RET_AUTH_INVALID_ID: 1015,
  MT_RET_AUTH_INVALID_IP: 1016,
  MT_RET_AUTH_INVALID_TYPE: 1017,
  MT_RET_AUTH_SERVER_BUSY: 1018,
  MT_RET_AUTH_SERVER_CERT: 1019,
  MT_RET_AUTH_ACCOUNT_UNKNOWN: 1020,
  MT_RET_AUTH_SERVER_OLD: 1021,
  MT_RET_AUTH_SERVER_LIMIT: 1022,
  MT_RET_AUTH_MOBILE_DISABLED: 1023,
  MT_RET_AUTH_MANAGER_TYPE: 1024,
  MT_RET_AUTH_DEMO_DISABLED: 1025,
  MT_RET_AUTH_RESET_PASSWORD: 1026,
  MT_RET_AUTH_OTP_INVALID: 1027,
  MT_RET_AUTH_OTP_NEED_SECRET: 1028,
  MT_RET_AUTH_INVALID_VERIFY: 1031,
  MT_RET_AUTH_VERIFY_BAD_EMAIL: 1032,
  MT_RET_AUTH_VERIFY_BAD_PHONE: 1033,
  MT_RET_AUTH_API_DISABLED: 1034,
  MT_RET_USR_LAST_ADMIN: 3001,
  MT_RET_USR_LOGIN_EXHAUSTED: 3002,
  MT_RET_USR_LOGIN_PROHIBITED: 3003,
  MT_RET_USR_LOGIN_EXIST: 3004,
  MT_RET_USR_SUICIDE: 3005,
  MT_RET_USR_INVALID_PASSWORD: 3006,
  MT_RET_USR_LIMIT_REACHED: 3007,
  MT_RET_USR_HAS_TRADES: 3008,
  MT_RET_USR_DIFFERENT_SERVERS: 3009,
  MT_RET_USR_DIFFERENT_CURRENCY: 3010,
  MT_RET_USR_IMPORT_BALANCE: 3011,
  MT_RET_USR_IMPORT_GROUP: 3012,
  MT_RET_USR_ACCOUNT_EXIST: 3013,
  MT_RET_USR_IMPORT_ACCOUNT: 3014,
  MT_RET_USR_IMPORT_POSITIONS: 3015,
  MT_RET_USR_IMPORT_ORDERS: 3016,
  MT_RET_USR_IMPORT_DEALS: 3017,
  MT_RET_USR_IMPORT_HISTORY: 3018,
  MT_RET_TRADE_LIMIT_REACHED: 4001,
  MT_RET_TRADE_ORDER_EXIST: 4002,
  MT_RET_TRADE_ORDER_EXHAUSTED: 4003,
  MT_RET_TRADE_DEAL_EXHAUSTED: 4004,
  MT_RET_TRADE_MAX_MONEY: 4005,
  MT_RET_TRADE_DEAL_EXIST: 4006,
  MT_RET_TRADE_ORDER_PROHIBITED: 4007,
  MT_RET_TRADE_DEAL_PROHIBITED: 4008,
  MT_RET_TRADE_SPLIT_VOLUME: 4009,
  MT_RET_HST_SYMBOL_NOTFOUND: 6001,
  MT_RET_REQUEST_INWAY: 10001,
  MT_RET_REQUEST_ACCEPTED: 10002,
  MT_RET_REQUEST_PROCESS: 10003,
  MT_RET_REQUEST_REQUOTE: 10004,
  MT_RET_REQUEST_PRICES: 10005,
  MT_RET_REQUEST_REJECT: 10006,
  MT_RET_REQUEST_CANCEL: 10007,
  MT_RET_REQUEST_PLACED: 10008,
  MT_RET_REQUEST_DONE: 10009,
  MT_RET_REQUEST_DONE_PARTIAL: 10010,
  MT_RET_REQUEST_ERROR: 10011,
  MT_RET_REQUEST_TIMEOUT: 10012,
  MT_RET_REQUEST_INVALID: 10013,
  MT_RET_REQUEST_INVALID_VOLUME: 10014,
  MT_RET_REQUEST_INVALID_PRICE: 10015,
  MT_RET_REQUEST_INVALID_STOPS: 10016,
  MT_RET_REQUEST_TRADE_DISABLED: 10017,
  MT_RET_REQUEST_MARKET_CLOSED: 10018,
  MT_RET_REQUEST_NO_MONEY: 10019,
  MT_RET_REQUEST_PRICE_CHANGED: 10020,
  MT_RET_REQUEST_PRICE_OFF: 10021,
  MT_RET_REQUEST_INVALID_EXP: 10022,
  MT_RET_REQUEST_ORDER_CHANGED: 10023,
  MT_RET_REQUEST_TOO_MANY: 10024,
  MT_RET_REQUEST_NO_CHANGES: 10025,
  MT_RET_REQUEST_AT_DISABLED_SERVER: 10026,
  MT_RET_REQUEST_AT_DISABLED_CLIENT: 10027,
  MT_RET_REQUEST_LOCKED: 10028,
  MT_RET_REQUEST_FROZEN: 10029,
  MT_RET_REQUEST_INVALID_FILL: 10030,
  MT_RET_REQUEST_CONNECTION: 10031,
  MT_RET_REQUEST_ONLY_REAL: 10032,
  MT_RET_REQUEST_LIMIT_ORDERS: 10033,
  MT_RET_REQUEST_LIMIT_VOLUME: 10034,
  MT_RET_REQUEST_INVALID_ORDER: 10035,
  MT_RET_REQUEST_POSITION_CLOSED: 10036,
  MT_RET_REQUEST_EXECUTION_SKIPPED: 10037,
  MT_RET_REQUEST_INVALID_CLOSE_VOLUME: 10038,
  MT_RET_REQUEST_CLOSE_ORDER_EXIST: 10039,
  MT_RET_REQUEST_LIMIT_POSITIONS: 10040,
  MT_RET_REQUEST_REJECT_CANCEL: 10041,
  MT_RET_REQUEST_LONG_ONLY: 10042,
  MT_RET_REQUEST_SHORT_ONLY: 10043,
  MT_RET_REQUEST_CLOSE_ONLY: 10044,
  MT_RET_REQUEST_PROHIBITED_BY_FIFO: 10045,
  MT_RET_REQUEST_HEDGE_PROHIBITED: 10046,
  MT_RET_USER_RIGHT_NONE: 20000,
  MT_RET_USER_RIGHT_ENABLED: 20001,
  MT_RET_USER_RIGHT_TRADE_DISABLED: 20002,
  MT_RET_USER_RIGHT_PASSWORD: 20003,
  MT_RET_USER_RIGHT_INVESTOR: 20004,
  MT_RET_USER_RIGHT_CONFIRMED: 20005,
  MT_RET_USER_RIGHT_TRAILING: 20006,
  MT_RET_USER_RIGHT_EXPERT: 20007,
  MT_RET_USER_RIGHT_OBSOLETE: 20008,
  MT_RET_USER_RIGHT_REPORTS: 20009,
  MT_RET_USER_RIGHT_READONLY: 20010,
  MT_RET_USER_RIGHT_RESET_PASS: 20011,
  MT_RET_USER_RIGHT_OTP_ENABLED: 20012,
  MT_RET_USER_RIGHT_SPONSORED_HOSTING: 20013,
  MT_RET_USER_RIGHT_API_ENABLED: 20014,
  MT_RET_USER_RIGHT_PUSH_NOTIFICATION: 20015
};

/**
 * @enum {number}
 */
proto.MT5WT.ExecutionNotiFlag = {
  EXECUTION_NOTI_FLAG_NONE: 0,
  EXECUTION_NOTI_FLAG_TRUE: 1,
  EXECUTION_NOTI_FLAG_FALSE: 2
};

/**
 * @enum {number}
 */
proto.MT5WT.Language = {
  LANGUAGE_NONE: 0,
  LANGUAGE_ENGLISH: 1,
  LANGUAGE_JAPANESE: 2
};

/**
 * @enum {number}
 */
proto.MT5WT.NotiType = {
  NOTI_PRICE_ALERT: 0,
  NOTI_ORDER_EXECUTE: 1
};

/**
 * @enum {number}
 */
proto.MT5WT.ShowSwap = {
  SWAP_TRUE: 0,
  SWAP_FALSE: 1
};

/**
 * @enum {number}
 */
proto.MT5WT.IsAdmin = {
  ADMIN_TRUE: 0,
  ADMIN_FALSE: 1
};

/**
 * @enum {number}
 */
proto.MT5WT.Group = {
  GROUP_NONE: 0,
  GROUP_CQJ: 1,
  GROUP_OTHER: 2
};

/**
 * @enum {number}
 */
proto.MT5WT.Books = {
  BOOK_NONE: 0,
  BOOK_ALL: 1,
  BOOK_BBOOK: 2
};

goog.object.extend(exports, proto.MT5WT);
