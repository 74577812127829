// source: pricing_model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.MT5WT.ChartBars', null, global);
goog.exportSymbol('proto.MT5WT.Quote', null, global);
goog.exportSymbol('proto.MT5WT.QuoteStat', null, global);
goog.exportSymbol('proto.MT5WT.Spread', null, global);
goog.exportSymbol('proto.MT5WT.SpreadEventType', null, global);
goog.exportSymbol('proto.MT5WT.TimeFrame', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.Quote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.Quote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.Quote.displayName = 'proto.MT5WT.Quote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.QuoteStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.QuoteStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.QuoteStat.displayName = 'proto.MT5WT.QuoteStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.ChartBars = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.ChartBars, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.ChartBars.displayName = 'proto.MT5WT.ChartBars';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.Spread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.Spread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.Spread.displayName = 'proto.MT5WT.Spread';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.Quote.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.Quote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.Quote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Quote.toObject = function(includeInstance, msg) {
  var f, obj = {
    datetime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ask: jspb.Message.getFieldWithDefault(msg, 3, ""),
    last: jspb.Message.getFieldWithDefault(msg, 4, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 5, ""),
    flag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 7, ""),
    quoteStat: (f = msg.getQuoteStat()) && proto.MT5WT.QuoteStat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.Quote}
 */
proto.MT5WT.Quote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.Quote;
  return proto.MT5WT.Quote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.Quote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.Quote}
 */
proto.MT5WT.Quote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsk(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlag(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 8:
      var value = new proto.MT5WT.QuoteStat;
      reader.readMessage(value,proto.MT5WT.QuoteStat.deserializeBinaryFromReader);
      msg.setQuoteStat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.Quote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.Quote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.Quote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Quote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAsk();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLast();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFlag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQuoteStat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.MT5WT.QuoteStat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string datetime = 1;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bid = 2;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getBid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setBid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ask = 3;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getAsk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setAsk = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last = 4;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getLast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setLast = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string volume = 5;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string flag = 6;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symbol = 7;
 * @return {string}
 */
proto.MT5WT.Quote.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional QuoteStat quote_stat = 8;
 * @return {?proto.MT5WT.QuoteStat}
 */
proto.MT5WT.Quote.prototype.getQuoteStat = function() {
  return /** @type{?proto.MT5WT.QuoteStat} */ (
    jspb.Message.getWrapperField(this, proto.MT5WT.QuoteStat, 8));
};


/**
 * @param {?proto.MT5WT.QuoteStat|undefined} value
 * @return {!proto.MT5WT.Quote} returns this
*/
proto.MT5WT.Quote.prototype.setQuoteStat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MT5WT.Quote} returns this
 */
proto.MT5WT.Quote.prototype.clearQuoteStat = function() {
  return this.setQuoteStat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MT5WT.Quote.prototype.hasQuoteStat = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.QuoteStat.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.QuoteStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.QuoteStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.QuoteStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceChange: jspb.Message.getFieldWithDefault(msg, 1, ""),
    askLow: jspb.Message.getFieldWithDefault(msg, 2, ""),
    askHigh: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bidLow: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bidHigh: jspb.Message.getFieldWithDefault(msg, 5, ""),
    datetime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    priceOpen: jspb.Message.getFieldWithDefault(msg, 7, ""),
    priceClose: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.QuoteStat}
 */
proto.MT5WT.QuoteStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.QuoteStat;
  return proto.MT5WT.QuoteStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.QuoteStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.QuoteStat}
 */
proto.MT5WT.QuoteStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceChange(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAskLow(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAskHigh(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidLow(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidHigh(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceOpen(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.QuoteStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.QuoteStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.QuoteStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.QuoteStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceChange();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAskLow();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAskHigh();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBidLow();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBidHigh();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPriceOpen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPriceClose();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string price_change = 1;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getPriceChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setPriceChange = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ask_low = 2;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getAskLow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setAskLow = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ask_high = 3;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getAskHigh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setAskHigh = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bid_low = 4;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getBidLow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setBidLow = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bid_high = 5;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getBidHigh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setBidHigh = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string datetime = 6;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string price_open = 7;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getPriceOpen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setPriceOpen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string price_close = 8;
 * @return {string}
 */
proto.MT5WT.QuoteStat.prototype.getPriceClose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.QuoteStat} returns this
 */
proto.MT5WT.QuoteStat.prototype.setPriceClose = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.ChartBars.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.ChartBars.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.ChartBars} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.ChartBars.toObject = function(includeInstance, msg) {
  var f, obj = {
    datetime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    open: jspb.Message.getFieldWithDefault(msg, 2, ""),
    high: jspb.Message.getFieldWithDefault(msg, 3, ""),
    low: jspb.Message.getFieldWithDefault(msg, 4, ""),
    close: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tickVolume: jspb.Message.getFieldWithDefault(msg, 6, ""),
    spread: jspb.Message.getFieldWithDefault(msg, 7, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.ChartBars}
 */
proto.MT5WT.ChartBars.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.ChartBars;
  return proto.MT5WT.ChartBars.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.ChartBars} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.ChartBars}
 */
proto.MT5WT.ChartBars.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpen(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHigh(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLow(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClose(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickVolume(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpread(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.ChartBars.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.ChartBars.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.ChartBars} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.ChartBars.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpen();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHigh();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLow();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClose();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTickVolume();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSpread();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string datetime = 1;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string open = 2;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getOpen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setOpen = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string high = 3;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getHigh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setHigh = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string low = 4;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getLow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setLow = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string close = 5;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getClose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setClose = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tick_volume = 6;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getTickVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setTickVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string spread = 7;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getSpread = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setSpread = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string volume = 8;
 * @return {string}
 */
proto.MT5WT.ChartBars.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.ChartBars} returns this
 */
proto.MT5WT.ChartBars.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.Spread.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.Spread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.Spread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Spread.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    spread: jspb.Message.getFieldWithDefault(msg, 2, 0),
    spreadBalance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    spreadDiff: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spreadDiffBalance: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.Spread}
 */
proto.MT5WT.Spread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.Spread;
  return proto.MT5WT.Spread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.Spread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.Spread}
 */
proto.MT5WT.Spread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpread(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpreadBalance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpreadDiff(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpreadDiffBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.Spread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.Spread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.Spread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.Spread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSpread();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSpreadBalance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSpreadDiff();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSpreadDiffBalance();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.MT5WT.Spread.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.Spread} returns this
 */
proto.MT5WT.Spread.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 spread = 2;
 * @return {number}
 */
proto.MT5WT.Spread.prototype.getSpread = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.Spread} returns this
 */
proto.MT5WT.Spread.prototype.setSpread = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 spread_balance = 3;
 * @return {number}
 */
proto.MT5WT.Spread.prototype.getSpreadBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.Spread} returns this
 */
proto.MT5WT.Spread.prototype.setSpreadBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 spread_diff = 4;
 * @return {number}
 */
proto.MT5WT.Spread.prototype.getSpreadDiff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.Spread} returns this
 */
proto.MT5WT.Spread.prototype.setSpreadDiff = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 spread_diff_balance = 5;
 * @return {number}
 */
proto.MT5WT.Spread.prototype.getSpreadDiffBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.Spread} returns this
 */
proto.MT5WT.Spread.prototype.setSpreadDiffBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.MT5WT.TimeFrame = {
  TIME_FRAME_NONE: 0,
  TIME_FRAME_M1: 1,
  TIME_FRAME_M5: 5,
  TIME_FRAME_M15: 15,
  TIME_FRAME_M30: 30,
  TIME_FRAME_H1: 60,
  TIME_FRAME_H4: 240,
  TIME_FRAME_D: 1001,
  TIME_FRAME_W: 1002,
  TIME_FRAME_MN: 1003
};

/**
 * @enum {number}
 */
proto.MT5WT.SpreadEventType = {
  SPREAD_NONE: 0,
  SPREAD_ADD: 1,
  SPREAD_UPDATE: 2,
  SPREAD_DELETE: 3,
  SPREAD_SYNC: 4
};

goog.object.extend(exports, proto.MT5WT);
