import { LIST_CFD_SYMBOL, LIST_FX_SYMBOL, LocalStorageKey } from "../models/constants";
import { Quote } from "../models/proto/pricing_model_pb";
import { GetLastQuoteResponse, QuoteEventResponse } from "../models/proto/pricing_service_pb";
import { Payload, RpcMessage } from "../models/proto/rpc_pb";
import { MsgCode } from "../models/proto/system_model_pb";
import { updateQuote } from "../redux/slice/quote.slice";
import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastQuotes, subcribeQuotes } from "../utils/proto.utils";
import { updateLastQuote } from "../redux/slice/system.slice";

export const SocketContext = createContext<unknown>(null);

interface ISocketProvider {
    children: React.ReactNode;
}

const SOCKET_RECONNECTION_TIMEOUT = 3000;
const NUMBER_RECONNECT = 5;
const EXPIRE_TIME = 7200000;
let timer: any = null;
let socketTimer: any = null;

export const SocketProvider = (props: ISocketProvider) => {
    const [ws, setWs] = useState<WebSocket>();
    const dispatch = useDispatch();
    const numberReconnect = useRef<number>(0);

    useEffect(() => {
        let currentTime = new Date().getTime();
        let lastActiveTime = localStorage.getItem(LocalStorageKey.LAST_ACTIVE_TIME) ?? String(currentTime);

        let diff = currentTime - Number(lastActiveTime);
        if (diff <= EXPIRE_TIME) {
            try {
                setWs(new WebSocket(process.env.REACT_APP_WSS_URL + ""));
            } catch (e) {
                console.log(e)
            }
        } else {
            localStorage.removeItem(LocalStorageKey.LAST_ACTIVE_TIME);
            window.location.reload();
        }
    }, [])

    useEffect(() => {
        const onOpen = () => {
            console.log("Websocket opened");
            localStorage.setItem(LocalStorageKey.LAST_ACTIVE_TIME, String(new Date().getTime()));
            getLastQuotes(ws, LIST_FX_SYMBOL);
            getLastQuotes(ws, LIST_CFD_SYMBOL);
            subcribeQuotes(ws, LIST_FX_SYMBOL);
            subcribeQuotes(ws, LIST_CFD_SYMBOL);
            clearInterval(socketTimer);
            timer = setInterval(() => {
                ping();
            }, 30000);
        }

        const onClose = () => {
            console.log("Websocket closed");
            clearInterval(timer);

            socketTimer = setInterval(() => {
                numberReconnect.current = numberReconnect.current + 1;

                if (numberReconnect.current <= NUMBER_RECONNECT) {
                    setWs(new WebSocket(process.env.REACT_APP_WSS_URL + ""));
                } else {
                    localStorage.removeItem(LocalStorageKey.LAST_ACTIVE_TIME);
                    window.location.reload();
                }
            }, SOCKET_RECONNECTION_TIMEOUT);
        }

        if (ws) {
            ws.binaryType = "arraybuffer";
            ws.onopen = onOpen;
            ws.onclose = onClose;
            ws.onmessage = handleReceiveMessage;
        }
        return () => {
            if (ws) ws.close();
        };
    }, [ws]);

    const ping = () => {
        ws?.send("ping");
    }

    const handleReceiveMessage = (msg: any) => {
        const rpc = RpcMessage.deserializeBinary(msg.data);
        const clazz = rpc.getPayloadClass();
        var response: Uint8Array = rpc.getPayloadData() as Uint8Array;
        switch (clazz) {
            case Payload.PRICING_LAST_QUOTE_RES:
                handleLastQuoteResponse(GetLastQuoteResponse.deserializeBinary(response));
                break;
            case Payload.PRICING_QUOTE_EVENT:
                handleQuoteEvent(QuoteEventResponse.deserializeBinary(response));
                break;
        }
    }

    const handleLastQuoteResponse = (res: GetLastQuoteResponse) => {
        if (res.getMsgCode() === MsgCode.MT_RET_OK) {
            for (let quote of res.getQuoteList()) {
                dispatch(updateLastQuote(quote));
                dispatch(updateQuote(quote));
            }
        }
    }

    const handleQuoteEvent = (res: QuoteEventResponse) => {
        if (res.getQuote()) {
            let quote = res.getQuote() as Quote;
            dispatch(updateQuote(quote));
        }
    }

    return (
        <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
    );
}