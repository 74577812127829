// source: pricing_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var pricing_model_pb = require('./pricing_model_pb.js');
goog.object.extend(proto, pricing_model_pb);
var system_model_pb = require('./system_model_pb.js');
goog.object.extend(proto, system_model_pb);
goog.exportSymbol('proto.MT5WT.GetChartRequest', null, global);
goog.exportSymbol('proto.MT5WT.GetChartResponse', null, global);
goog.exportSymbol('proto.MT5WT.GetLastQuoteRequest', null, global);
goog.exportSymbol('proto.MT5WT.GetLastQuoteResponse', null, global);
goog.exportSymbol('proto.MT5WT.QuoteEventResponse', null, global);
goog.exportSymbol('proto.MT5WT.SpreadEventResponse', null, global);
goog.exportSymbol('proto.MT5WT.SubscribeQuoteRequest', null, global);
goog.exportSymbol('proto.MT5WT.SubscribeQuoteResponse', null, global);
goog.exportSymbol('proto.MT5WT.UnsubscribeQuoteRequest', null, global);
goog.exportSymbol('proto.MT5WT.UnsubscribeQuoteResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.GetLastQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.GetLastQuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.GetLastQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.GetLastQuoteRequest.displayName = 'proto.MT5WT.GetLastQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.QuoteEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.QuoteEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.QuoteEventResponse.displayName = 'proto.MT5WT.QuoteEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.GetLastQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.GetLastQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.GetLastQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.GetLastQuoteResponse.displayName = 'proto.MT5WT.GetLastQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.SubscribeQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.SubscribeQuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.SubscribeQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.SubscribeQuoteRequest.displayName = 'proto.MT5WT.SubscribeQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.SubscribeQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.SubscribeQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.SubscribeQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.SubscribeQuoteResponse.displayName = 'proto.MT5WT.SubscribeQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.UnsubscribeQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.UnsubscribeQuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.UnsubscribeQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.UnsubscribeQuoteRequest.displayName = 'proto.MT5WT.UnsubscribeQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.UnsubscribeQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.UnsubscribeQuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.UnsubscribeQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.UnsubscribeQuoteResponse.displayName = 'proto.MT5WT.UnsubscribeQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.GetChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.GetChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.GetChartRequest.displayName = 'proto.MT5WT.GetChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.GetChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.GetChartResponse.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.GetChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.GetChartResponse.displayName = 'proto.MT5WT.GetChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.SpreadEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MT5WT.SpreadEventResponse.repeatedFields_, null);
};
goog.inherits(proto.MT5WT.SpreadEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.SpreadEventResponse.displayName = 'proto.MT5WT.SpreadEventResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.GetLastQuoteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.GetLastQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.GetLastQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.GetLastQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetLastQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.GetLastQuoteRequest}
 */
proto.MT5WT.GetLastQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.GetLastQuoteRequest;
  return proto.MT5WT.GetLastQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.GetLastQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.GetLastQuoteRequest}
 */
proto.MT5WT.GetLastQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.GetLastQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.GetLastQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.GetLastQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetLastQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbol = 1;
 * @return {!Array<string>}
 */
proto.MT5WT.GetLastQuoteRequest.prototype.getSymbolList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.MT5WT.GetLastQuoteRequest} returns this
 */
proto.MT5WT.GetLastQuoteRequest.prototype.setSymbolList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.GetLastQuoteRequest} returns this
 */
proto.MT5WT.GetLastQuoteRequest.prototype.addSymbol = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.GetLastQuoteRequest} returns this
 */
proto.MT5WT.GetLastQuoteRequest.prototype.clearSymbolList = function() {
  return this.setSymbolList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.QuoteEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.QuoteEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.QuoteEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.QuoteEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    quote: (f = msg.getQuote()) && pricing_model_pb.Quote.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.QuoteEventResponse}
 */
proto.MT5WT.QuoteEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.QuoteEventResponse;
  return proto.MT5WT.QuoteEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.QuoteEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.QuoteEventResponse}
 */
proto.MT5WT.QuoteEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pricing_model_pb.Quote;
      reader.readMessage(value,pricing_model_pb.Quote.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.QuoteEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.QuoteEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.QuoteEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.QuoteEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pricing_model_pb.Quote.serializeBinaryToWriter
    );
  }
};


/**
 * optional Quote quote = 1;
 * @return {?proto.MT5WT.Quote}
 */
proto.MT5WT.QuoteEventResponse.prototype.getQuote = function() {
  return /** @type{?proto.MT5WT.Quote} */ (
    jspb.Message.getWrapperField(this, pricing_model_pb.Quote, 1));
};


/**
 * @param {?proto.MT5WT.Quote|undefined} value
 * @return {!proto.MT5WT.QuoteEventResponse} returns this
*/
proto.MT5WT.QuoteEventResponse.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MT5WT.QuoteEventResponse} returns this
 */
proto.MT5WT.QuoteEventResponse.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MT5WT.QuoteEventResponse.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.GetLastQuoteResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.GetLastQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.GetLastQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetLastQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quoteList: jspb.Message.toObjectList(msg.getQuoteList(),
    pricing_model_pb.Quote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.GetLastQuoteResponse}
 */
proto.MT5WT.GetLastQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.GetLastQuoteResponse;
  return proto.MT5WT.GetLastQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.GetLastQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.GetLastQuoteResponse}
 */
proto.MT5WT.GetLastQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.MT5WT.MsgCode} */ (reader.readEnum());
      msg.setMsgCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgText(value);
      break;
    case 3:
      var value = new pricing_model_pb.Quote;
      reader.readMessage(value,pricing_model_pb.Quote.deserializeBinaryFromReader);
      msg.addQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.GetLastQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.GetLastQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetLastQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsgText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuoteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      pricing_model_pb.Quote.serializeBinaryToWriter
    );
  }
};


/**
 * optional MsgCode msg_code = 1;
 * @return {!proto.MT5WT.MsgCode}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.getMsgCode = function() {
  return /** @type {!proto.MT5WT.MsgCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.MT5WT.MsgCode} value
 * @return {!proto.MT5WT.GetLastQuoteResponse} returns this
 */
proto.MT5WT.GetLastQuoteResponse.prototype.setMsgCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg_text = 2;
 * @return {string}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.getMsgText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.GetLastQuoteResponse} returns this
 */
proto.MT5WT.GetLastQuoteResponse.prototype.setMsgText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Quote quote = 3;
 * @return {!Array<!proto.MT5WT.Quote>}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.getQuoteList = function() {
  return /** @type{!Array<!proto.MT5WT.Quote>} */ (
    jspb.Message.getRepeatedWrapperField(this, pricing_model_pb.Quote, 3));
};


/**
 * @param {!Array<!proto.MT5WT.Quote>} value
 * @return {!proto.MT5WT.GetLastQuoteResponse} returns this
*/
proto.MT5WT.GetLastQuoteResponse.prototype.setQuoteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.MT5WT.Quote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.Quote}
 */
proto.MT5WT.GetLastQuoteResponse.prototype.addQuote = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.MT5WT.Quote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.GetLastQuoteResponse} returns this
 */
proto.MT5WT.GetLastQuoteResponse.prototype.clearQuoteList = function() {
  return this.setQuoteList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.SubscribeQuoteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.SubscribeQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.SubscribeQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SubscribeQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.SubscribeQuoteRequest}
 */
proto.MT5WT.SubscribeQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.SubscribeQuoteRequest;
  return proto.MT5WT.SubscribeQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.SubscribeQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.SubscribeQuoteRequest}
 */
proto.MT5WT.SubscribeQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.SubscribeQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.SubscribeQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SubscribeQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbol = 1;
 * @return {!Array<string>}
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.getSymbolList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.MT5WT.SubscribeQuoteRequest} returns this
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.setSymbolList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.SubscribeQuoteRequest} returns this
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.addSymbol = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.SubscribeQuoteRequest} returns this
 */
proto.MT5WT.SubscribeQuoteRequest.prototype.clearSymbolList = function() {
  return this.setSymbolList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.SubscribeQuoteResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.SubscribeQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.SubscribeQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SubscribeQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quoteList: jspb.Message.toObjectList(msg.getQuoteList(),
    pricing_model_pb.Quote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.SubscribeQuoteResponse}
 */
proto.MT5WT.SubscribeQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.SubscribeQuoteResponse;
  return proto.MT5WT.SubscribeQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.SubscribeQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.SubscribeQuoteResponse}
 */
proto.MT5WT.SubscribeQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.MT5WT.MsgCode} */ (reader.readEnum());
      msg.setMsgCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgText(value);
      break;
    case 3:
      var value = new pricing_model_pb.Quote;
      reader.readMessage(value,pricing_model_pb.Quote.deserializeBinaryFromReader);
      msg.addQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.SubscribeQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.SubscribeQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SubscribeQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsgText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuoteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      pricing_model_pb.Quote.serializeBinaryToWriter
    );
  }
};


/**
 * optional MsgCode msg_code = 1;
 * @return {!proto.MT5WT.MsgCode}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.getMsgCode = function() {
  return /** @type {!proto.MT5WT.MsgCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.MT5WT.MsgCode} value
 * @return {!proto.MT5WT.SubscribeQuoteResponse} returns this
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.setMsgCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg_text = 2;
 * @return {string}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.getMsgText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.SubscribeQuoteResponse} returns this
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.setMsgText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Quote quote = 3;
 * @return {!Array<!proto.MT5WT.Quote>}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.getQuoteList = function() {
  return /** @type{!Array<!proto.MT5WT.Quote>} */ (
    jspb.Message.getRepeatedWrapperField(this, pricing_model_pb.Quote, 3));
};


/**
 * @param {!Array<!proto.MT5WT.Quote>} value
 * @return {!proto.MT5WT.SubscribeQuoteResponse} returns this
*/
proto.MT5WT.SubscribeQuoteResponse.prototype.setQuoteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.MT5WT.Quote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.Quote}
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.addQuote = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.MT5WT.Quote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.SubscribeQuoteResponse} returns this
 */
proto.MT5WT.SubscribeQuoteResponse.prototype.clearQuoteList = function() {
  return this.setQuoteList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.UnsubscribeQuoteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.UnsubscribeQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.UnsubscribeQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.UnsubscribeQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.UnsubscribeQuoteRequest}
 */
proto.MT5WT.UnsubscribeQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.UnsubscribeQuoteRequest;
  return proto.MT5WT.UnsubscribeQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.UnsubscribeQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.UnsubscribeQuoteRequest}
 */
proto.MT5WT.UnsubscribeQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.UnsubscribeQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.UnsubscribeQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.UnsubscribeQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string symbol = 1;
 * @return {!Array<string>}
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.getSymbolList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.MT5WT.UnsubscribeQuoteRequest} returns this
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.setSymbolList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.UnsubscribeQuoteRequest} returns this
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.addSymbol = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.UnsubscribeQuoteRequest} returns this
 */
proto.MT5WT.UnsubscribeQuoteRequest.prototype.clearSymbolList = function() {
  return this.setSymbolList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.UnsubscribeQuoteResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.UnsubscribeQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.UnsubscribeQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.UnsubscribeQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quoteList: jspb.Message.toObjectList(msg.getQuoteList(),
    pricing_model_pb.Quote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse}
 */
proto.MT5WT.UnsubscribeQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.UnsubscribeQuoteResponse;
  return proto.MT5WT.UnsubscribeQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.UnsubscribeQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse}
 */
proto.MT5WT.UnsubscribeQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.MT5WT.MsgCode} */ (reader.readEnum());
      msg.setMsgCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgText(value);
      break;
    case 3:
      var value = new pricing_model_pb.Quote;
      reader.readMessage(value,pricing_model_pb.Quote.deserializeBinaryFromReader);
      msg.addQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.UnsubscribeQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.UnsubscribeQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.UnsubscribeQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsgText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuoteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      pricing_model_pb.Quote.serializeBinaryToWriter
    );
  }
};


/**
 * optional MsgCode msg_code = 1;
 * @return {!proto.MT5WT.MsgCode}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.getMsgCode = function() {
  return /** @type {!proto.MT5WT.MsgCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.MT5WT.MsgCode} value
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse} returns this
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.setMsgCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg_text = 2;
 * @return {string}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.getMsgText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse} returns this
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.setMsgText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Quote quote = 3;
 * @return {!Array<!proto.MT5WT.Quote>}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.getQuoteList = function() {
  return /** @type{!Array<!proto.MT5WT.Quote>} */ (
    jspb.Message.getRepeatedWrapperField(this, pricing_model_pb.Quote, 3));
};


/**
 * @param {!Array<!proto.MT5WT.Quote>} value
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse} returns this
*/
proto.MT5WT.UnsubscribeQuoteResponse.prototype.setQuoteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.MT5WT.Quote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.Quote}
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.addQuote = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.MT5WT.Quote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.UnsubscribeQuoteResponse} returns this
 */
proto.MT5WT.UnsubscribeQuoteResponse.prototype.clearQuoteList = function() {
  return this.setQuoteList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.GetChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.GetChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.GetChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromTs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toTs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.GetChartRequest}
 */
proto.MT5WT.GetChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.GetChartRequest;
  return proto.MT5WT.GetChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.GetChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.GetChartRequest}
 */
proto.MT5WT.GetChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromTs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToTs(value);
      break;
    case 4:
      var value = /** @type {!proto.MT5WT.TimeFrame} */ (reader.readEnum());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.GetChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.GetChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.GetChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromTs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToTs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimeFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.MT5WT.GetChartRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.GetChartRequest} returns this
 */
proto.MT5WT.GetChartRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from_ts = 2;
 * @return {number}
 */
proto.MT5WT.GetChartRequest.prototype.getFromTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.GetChartRequest} returns this
 */
proto.MT5WT.GetChartRequest.prototype.setFromTs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_ts = 3;
 * @return {number}
 */
proto.MT5WT.GetChartRequest.prototype.getToTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.GetChartRequest} returns this
 */
proto.MT5WT.GetChartRequest.prototype.setToTs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TimeFrame time_frame = 4;
 * @return {!proto.MT5WT.TimeFrame}
 */
proto.MT5WT.GetChartRequest.prototype.getTimeFrame = function() {
  return /** @type {!proto.MT5WT.TimeFrame} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.MT5WT.TimeFrame} value
 * @return {!proto.MT5WT.GetChartRequest} returns this
 */
proto.MT5WT.GetChartRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.GetChartResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.GetChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.GetChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.GetChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msgText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chartBarsList: jspb.Message.toObjectList(msg.getChartBarsList(),
    pricing_model_pb.ChartBars.toObject, includeInstance),
    barsTotal: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.GetChartResponse}
 */
proto.MT5WT.GetChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.GetChartResponse;
  return proto.MT5WT.GetChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.GetChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.GetChartResponse}
 */
proto.MT5WT.GetChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.MT5WT.MsgCode} */ (reader.readEnum());
      msg.setMsgCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = new pricing_model_pb.ChartBars;
      reader.readMessage(value,pricing_model_pb.ChartBars.deserializeBinaryFromReader);
      msg.addChartBars(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBarsTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.GetChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.GetChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.GetChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.GetChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsgText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChartBarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      pricing_model_pb.ChartBars.serializeBinaryToWriter
    );
  }
  f = message.getBarsTotal();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional MsgCode msg_code = 1;
 * @return {!proto.MT5WT.MsgCode}
 */
proto.MT5WT.GetChartResponse.prototype.getMsgCode = function() {
  return /** @type {!proto.MT5WT.MsgCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.MT5WT.MsgCode} value
 * @return {!proto.MT5WT.GetChartResponse} returns this
 */
proto.MT5WT.GetChartResponse.prototype.setMsgCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg_text = 2;
 * @return {string}
 */
proto.MT5WT.GetChartResponse.prototype.getMsgText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.GetChartResponse} returns this
 */
proto.MT5WT.GetChartResponse.prototype.setMsgText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.MT5WT.GetChartResponse.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.GetChartResponse} returns this
 */
proto.MT5WT.GetChartResponse.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ChartBars chart_bars = 4;
 * @return {!Array<!proto.MT5WT.ChartBars>}
 */
proto.MT5WT.GetChartResponse.prototype.getChartBarsList = function() {
  return /** @type{!Array<!proto.MT5WT.ChartBars>} */ (
    jspb.Message.getRepeatedWrapperField(this, pricing_model_pb.ChartBars, 4));
};


/**
 * @param {!Array<!proto.MT5WT.ChartBars>} value
 * @return {!proto.MT5WT.GetChartResponse} returns this
*/
proto.MT5WT.GetChartResponse.prototype.setChartBarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.MT5WT.ChartBars=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.ChartBars}
 */
proto.MT5WT.GetChartResponse.prototype.addChartBars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.MT5WT.ChartBars, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.GetChartResponse} returns this
 */
proto.MT5WT.GetChartResponse.prototype.clearChartBarsList = function() {
  return this.setChartBarsList([]);
};


/**
 * optional int64 bars_total = 5;
 * @return {number}
 */
proto.MT5WT.GetChartResponse.prototype.getBarsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.GetChartResponse} returns this
 */
proto.MT5WT.GetChartResponse.prototype.setBarsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MT5WT.SpreadEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.SpreadEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.SpreadEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.SpreadEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SpreadEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spreadList: jspb.Message.toObjectList(msg.getSpreadList(),
    pricing_model_pb.Spread.toObject, includeInstance),
    spreadEventType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.SpreadEventResponse}
 */
proto.MT5WT.SpreadEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.SpreadEventResponse;
  return proto.MT5WT.SpreadEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.SpreadEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.SpreadEventResponse}
 */
proto.MT5WT.SpreadEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pricing_model_pb.Spread;
      reader.readMessage(value,pricing_model_pb.Spread.deserializeBinaryFromReader);
      msg.addSpread(value);
      break;
    case 2:
      var value = /** @type {!proto.MT5WT.SpreadEventType} */ (reader.readEnum());
      msg.setSpreadEventType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.SpreadEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.SpreadEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.SpreadEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.SpreadEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpreadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      pricing_model_pb.Spread.serializeBinaryToWriter
    );
  }
  f = message.getSpreadEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated Spread spread = 1;
 * @return {!Array<!proto.MT5WT.Spread>}
 */
proto.MT5WT.SpreadEventResponse.prototype.getSpreadList = function() {
  return /** @type{!Array<!proto.MT5WT.Spread>} */ (
    jspb.Message.getRepeatedWrapperField(this, pricing_model_pb.Spread, 1));
};


/**
 * @param {!Array<!proto.MT5WT.Spread>} value
 * @return {!proto.MT5WT.SpreadEventResponse} returns this
*/
proto.MT5WT.SpreadEventResponse.prototype.setSpreadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.MT5WT.Spread=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MT5WT.Spread}
 */
proto.MT5WT.SpreadEventResponse.prototype.addSpread = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.MT5WT.Spread, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MT5WT.SpreadEventResponse} returns this
 */
proto.MT5WT.SpreadEventResponse.prototype.clearSpreadList = function() {
  return this.setSpreadList([]);
};


/**
 * optional SpreadEventType spread_event_type = 2;
 * @return {!proto.MT5WT.SpreadEventType}
 */
proto.MT5WT.SpreadEventResponse.prototype.getSpreadEventType = function() {
  return /** @type {!proto.MT5WT.SpreadEventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.MT5WT.SpreadEventType} value
 * @return {!proto.MT5WT.SpreadEventResponse} returns this
 */
proto.MT5WT.SpreadEventResponse.prototype.setSpreadEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.MT5WT);
