import React, {useEffect, useState} from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { DEFAULT_LANG, LocalStorageKey, RouteUrl } from './models/constants';
import logo from './logo.svg';
import './App.css';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { useTranslation } from 'react-i18next';

if (typeof window !== "undefined") {
	require("bootstrap/dist/js/bootstrap.bundle.min");
}

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const MarketWatch = React.lazy(() => import('./pages/market-watch'));

const App = () => {
	const {i18n} = useTranslation();
	let currentLanguage = DEFAULT_LANG;
    if (localStorage.getItem(LocalStorageKey.LANGUAGE))
        currentLanguage = localStorage.getItem(LocalStorageKey.LANGUAGE) as string;

    const [language, setLanguage] = useState(currentLanguage);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])

	return (
		<BrowserRouter>
			<React.Suspense fallback={loading}>
				<Routes>
					<Route path="/" element={<Navigate to={RouteUrl.MARKET_WATCH} />} />
					<Route path={RouteUrl.MARKET_WATCH} element={<MarketWatch />} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
  	)
}

export default App;
