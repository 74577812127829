import moment from "moment";

export const isEmpty = (value: any) => {
    return (undefined === value) || (null === value) || (0 === value.trim().length);
}

export const isNull = (value: any) => {
    return (undefined === value) || (null === value);
}

export const roundDown = (value: number, digit: number) => {
    let powVal = Math.pow(10, digit);
    return (Math.floor(value * powVal)) / powVal;
}

export const formatNumber = (value: any, digit: number) => {
    if (!isNull(value) && !isNaN(value))
        return new Intl.NumberFormat('en-us', { minimumFractionDigits: digit, maximumFractionDigits: digit }).format(value);
    else return "";
}

export const convertNumber = (value: any) => {
    const tmpValue = value?.toString().replaceAll(',', '');
    if (tmpValue && !isNaN(Number(tmpValue))) {
        return Number(tmpValue);
    }
    return 0;
}