import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Quote } from '../../models/proto/pricing_model_pb';

export interface QuoteState {
    [symbol: string]: Quote
}

const initialState: QuoteState = {

}

export const quoteSlice = createSlice({
    name: "quote",
    initialState,
    reducers: {
        updateQuote: (state, action: PayloadAction<Quote>) => {
            const quote = action.payload as Quote;
            return {
                ...state,
                [quote.getSymbol()]: quote
            }
        }
    }
})

export const {updateQuote} = quoteSlice.actions;
export default quoteSlice.reducer;