export const LocalStorageKey = {
    LANGUAGE: 'language',
    LAST_ACTIVE_TIME: "last-active-time",
}

export const RouteUrl = {
    MARKET_WATCH: "/market-watch"
}

export enum WebsocketState { CONNECTING = 0, OPEN = 1, CLOSING = 2, CLOSED = 3}
export enum SymbolType {FX = 0, CFD = 1}

export const RpcService = {
    SYSTEM: "system",
    PRICING: "pricing",
    TRADING: "trading",
    CUSTOMER: "customer",
    MARKET: "market"
}

export const DIGIT_SYMBOL: Map<string, number> = new Map([
    ["USDJPY", 3],
    ["EURUSD", 5],
    ["EURJPY", 3],
    ["GBPJPY", 3],
    ["AUDJPY", 3],
    ["NZDJPY", 3],
    ["JPXJPY", 2],
    ["DJIUSD", 2],
    ["XAUUSD", 2],
    ["XTIUSD", 3]
]);

export const DEFAULT_LANG = "ja";

export const LIST_FX_SYMBOL = ["USDJPY.ps01", "EURUSD.ps01", "EURJPY.ps01", "GBPJPY.ps01", "AUDJPY.ps01", "NZDJPY.ps01"];
export const LIST_CFD_SYMBOL = ["JPXJPY.ps01", "DJIUSD.ps01", "XAUUSD.ps01", "XTIUSD.ps01"];