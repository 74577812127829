// source: rpc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.MT5WT.Payload', null, global);
goog.exportSymbol('proto.MT5WT.Result', null, global);
goog.exportSymbol('proto.MT5WT.RpcMessage', null, global);
goog.exportSymbol('proto.MT5WT.Source', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MT5WT.RpcMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MT5WT.RpcMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MT5WT.RpcMessage.displayName = 'proto.MT5WT.RpcMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MT5WT.RpcMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.MT5WT.RpcMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MT5WT.RpcMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.RpcMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    result: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, ""),
    service: jspb.Message.getFieldWithDefault(msg, 4, ""),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    contextId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    payloadData: msg.getPayloadData_asB64(),
    payloadClass: jspb.Message.getFieldWithDefault(msg, 8, 0),
    compress: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    paging: jspb.Message.getFieldWithDefault(msg, 11, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 12, 0),
    replyTo: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MT5WT.RpcMessage}
 */
proto.MT5WT.RpcMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MT5WT.RpcMessage;
  return proto.MT5WT.RpcMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MT5WT.RpcMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MT5WT.RpcMessage}
 */
proto.MT5WT.RpcMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.MT5WT.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 5:
      var value = /** @type {!proto.MT5WT.Source} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContextId(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayloadData(value);
      break;
    case 8:
      var value = /** @type {!proto.MT5WT.Payload} */ (reader.readEnum());
      msg.setPayloadClass(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompress(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaging(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReplyTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MT5WT.RpcMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MT5WT.RpcMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MT5WT.RpcMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MT5WT.RpcMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getService();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getContextId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPayloadData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getPayloadClass();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCompress();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPaging();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getReplyTo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.MT5WT.RpcMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Result result = 2;
 * @return {!proto.MT5WT.Result}
 */
proto.MT5WT.RpcMessage.prototype.getResult = function() {
  return /** @type {!proto.MT5WT.Result} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.MT5WT.Result} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.MT5WT.RpcMessage.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service = 4;
 * @return {string}
 */
proto.MT5WT.RpcMessage.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setService = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Source source = 5;
 * @return {!proto.MT5WT.Source}
 */
proto.MT5WT.RpcMessage.prototype.getSource = function() {
  return /** @type {!proto.MT5WT.Source} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.MT5WT.Source} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 context_id = 6;
 * @return {number}
 */
proto.MT5WT.RpcMessage.prototype.getContextId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setContextId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bytes payload_data = 7;
 * @return {!(string|Uint8Array)}
 */
proto.MT5WT.RpcMessage.prototype.getPayloadData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes payload_data = 7;
 * This is a type-conversion wrapper around `getPayloadData()`
 * @return {string}
 */
proto.MT5WT.RpcMessage.prototype.getPayloadData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayloadData()));
};


/**
 * optional bytes payload_data = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayloadData()`
 * @return {!Uint8Array}
 */
proto.MT5WT.RpcMessage.prototype.getPayloadData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayloadData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setPayloadData = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional Payload payload_class = 8;
 * @return {!proto.MT5WT.Payload}
 */
proto.MT5WT.RpcMessage.prototype.getPayloadClass = function() {
  return /** @type {!proto.MT5WT.Payload} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.MT5WT.Payload} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setPayloadClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool compress = 10;
 * @return {boolean}
 */
proto.MT5WT.RpcMessage.prototype.getCompress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setCompress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 paging = 11;
 * @return {number}
 */
proto.MT5WT.RpcMessage.prototype.getPaging = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setPaging = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 limit = 12;
 * @return {number}
 */
proto.MT5WT.RpcMessage.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string reply_to = 13;
 * @return {string}
 */
proto.MT5WT.RpcMessage.prototype.getReplyTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.MT5WT.RpcMessage} returns this
 */
proto.MT5WT.RpcMessage.prototype.setReplyTo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * @enum {number}
 */
proto.MT5WT.Result = {
  RESULT_NONE: 0,
  INVALID_VERSION: 2,
  INVALID_SERVICE: 3,
  INVALID_PAYLOAD: 4,
  NOT_AUTHENTICATED: 5,
  SERVICE_TIMEOUT: 6,
  SERVICE_REJECTED: 7,
  SERVICE_UNAVAILABLE: 8,
  REQUIRE_VPN: 9,
  MAINTAINANCE: 10,
  INTERNAL_ERROR: 99,
  SUCCESS: 100
};

/**
 * @enum {number}
 */
proto.MT5WT.Source = {
  SOURCE_NONE: 0,
  IOS: 1,
  ANDROID: 2,
  EXTERNAL: 9,
  WEB: 10
};

/**
 * @enum {number}
 */
proto.MT5WT.Payload = {
  PAYLOAD_NONE: 0,
  SYSTEM_LOGIN_REQ: 1,
  SYSTEM_LOGIN_RES: 2,
  SYSTEM_HEARTBEAT_REQ: 3,
  SYSTEM_HEARTBEAT_RES: 4,
  SYSTEM_GUEST_LOGIN_REQ: 5,
  SYSTEM_GUEST_LOGIN_RES: 6,
  SYSTEM_VERSION_CHECK_REQ: 7,
  SYSTEM_VERSION_CHECK_RES: 8,
  SYSTEM_PRICE_ALERT_SETTING_REQ: 9,
  SYSTEM_PRICE_ALERT_SETTING_RES: 10,
  SYSTEM_EXECUTION_NOTI_REQ: 11,
  SYSTEM_EXECUTION_NOTI_RES: 12,
  SYSTEN_EXECUTION_NOTI_CHECK_REQ: 13,
  SYSTEN_EXECUTION_NOTI_CHECK_RES: 14,
  SYSTEM_LANGUAGE_SETTING_REQ: 15,
  SYSTEM_LANGUAGE_SETTING_RES: 16,
  SYSTEM_PASSWORD_CHECKING_REQ: 17,
  SYSTEM_PASSWORD_CHECKING_RES: 18,
  SYSTEM_NOTIFICATION_EVENT_RES: 19,
  SYSTEM_NOTIFICATIONS_REQ: 20,
  SYSTEM_NOTIFICATIONS_RES: 21,
  PRICING_LAST_QUOTE_REQ: 22,
  PRICING_SUBSCRIBE_QUOTE_REQ: 23,
  PRICING_UNSUBSCRIBE_QUOTE_REQ: 24,
  PRICING_QUOTE_EVENT: 25,
  PRICING_CHART_REQ: 26,
  PRICING_LAST_QUOTE_RES: 27,
  PRICING_SUBSCRIBE_QUOTE_RES: 28,
  PRICING_UNSUBSCRIBE_QUOTE_RES: 29,
  PRICING_CHART_RES: 30,
  PRICING_SPREAD_EVENT: 31,
  TRADING_PLACE_NEW_ORDER_REQ: 60,
  TRADING_PLACE_PENDING_ORDER_REQ: 61,
  TRADING_MODIFY_ORDER_REQ: 62,
  TRADING_REMOVE_ORDER_REQ: 63,
  TRADING_CLOSE_POSITION_REQ: 64,
  TRADING_MODIFY_POSITION_REQ: 65,
  TRADING_ORDER_EVENT: 66,
  TRADING_POSITION_EVENT: 67,
  TRADING_CLOSE_ALL_POSITION_REQ: 68,
  TRADING_PLACE_NEW_ORDER_RES: 70,
  TRADING_PLACE_PENDING_ORDER_RES: 71,
  TRADING_MODIFY_ORDER_RES: 72,
  TRADING_REMOVE_ORDER_RES: 73,
  TRADING_CLOSE_POSITION_RES: 74,
  TRADING_MODIFY_POSITION_RES: 75,
  TRADING_CLOSE_ALL_POSITION_RES: 76,
  QUERRY_ACCOUNT_BALANCE_REQ: 83,
  QUERRY_ACCOUNT_BALANCE_EVENT: 84,
  QUERRY_ACCOUNT_BALANCE_RES: 93,
  SYSTEM_LOCK_ACCOUNT_REQ: 94,
  SYSTEM_LOCK_ACCOUNT_RES: 95,
  QUERRY_SYMBOL_LIST_REQ: 100,
  QUERRY_ORDER_LIST_REQ: 101,
  QUERRY_DEAL_LIST_REQ: 102,
  QUERRY_POSITION_LIST_REQ: 103,
  QUERRY_ADD_FAVOURITE_LIST_REQ: 104,
  QUERRY_SYMBOL_REQ: 105,
  QUERRY_PENDING_ORDER_LIST_REQ: 106,
  QUERRY_SYMBOL_LIST_RES: 110,
  QUERRY_ORDER_LIST_RES: 111,
  QUERRY_DEAL_LIST_RES: 112,
  QUERRY_POSITION_LIST_RES: 113,
  QUERRY_ADD_FAVOURITE_LIST_RES: 114,
  QUERRY_SYMBOL_RES: 115,
  QUERRY_PENDING_ORDER_LIST_RES: 116,
  QUERRY_SYMBOL_LIST_MT5_REQ: 118,
  QUERRY_SYMBOL_LIST_MT5_RES: 119,
  QUERRY_PRICE_ALERT_LIST_REQ: 120,
  QUERRY_PRICE_ALERT_LIST_RES: 121
};

goog.object.extend(exports, proto.MT5WT);
